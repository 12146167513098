<template>
    <label v-if="label" :for="id">{{ label }}</label>
    <input
        :id="id"
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        v-model="inputValue"
    >
</template>

<script>
export default {
    props: {
        name: {
            type: String
        },
        id: {
            type: String
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: String
        },
        placeholder: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],

    data() {
        return {
            inputValue: this.value
        };
    },

    watch: {
        value(newValue) {
            this.inputValue = newValue;
        },
        inputValue(newValue) {
            this.$emit('update:modelValue', newValue);
        }
    }
}
</script>