import baseFields from '../fields'
import { cloneDeep } from 'lodash'
import { required } from '../rules'

let fields = cloneDeep(baseFields)

let mandatoryFields = [
  'programs',
  'first-name',
  'last-name',
  'email',
  'phone',
  'zipcode',
  'sms-consent-checkbox'
]

let customFields = [

  // SMS Consent (Checkbox)
  ...fields.filter(f => f.id === 'sms-consent-checkbox').map(field => {
    return {
      ...field,
      meta: {
        ...field.meta,
        label: 'I authorize [PARTNER_NAME] and its representatives to contact me via SMS. I am providing my consent by leaving the opt-in checked. Message and data rates may apply. [LINK_1].',
        links: [
          {
            text: 'Terms',
            url: 'https://policies.edusites.net/terms'
          }
        ]
      }
    }
  }),
   // First Name 
   ...fields.filter(f => f.id === 'first-name').map(field => {
    return {
      ...field,
      meta: {
        ...field.meta,
        classes:['half-width']
      },
    }
  }),
    // Last Name 
   ...fields.filter(f => f.id === 'last-name').map(field => {
      return {
        ...field,
        meta: {
          ...field.meta,
          classes:['half-width']
        },
      }
    }),
      // Phone 
    ...fields.filter(f => f.id === 'phone').map(field => {
        return {
          ...field,
          meta: {
            ...field.meta,
            classes:['half-width']
          },
        }
      }),
        // Zip 
    ...fields.filter(f => f.id === 'zipcode').map(field => {
      return {
        ...field,
        meta: {
          ...field.meta,
          classes:['half-width']
        },
      }
    }),

]

let data = [
  ...cloneDeep(baseFields.map(field => {
    if (customFields.map(({id}) => id).includes(field.id)) {
      return { ...customFields.find(({id}) => id === field.id) }
    } else {
      return { ...field }
    }
  }))
]

data.forEach(field => {
  // Set all of the required fields.
  if (mandatoryFields.includes(field.id)) {
    field.admin.mandatory = true

    if (!field.admin.required_never) {
      field.admin.required = true
      field.meta.rules.push({ ...required })
      // don't let this be toggled off
      field.admin.required_always = true
    }

  }
})

export default data
