<template>
    <component
      :is="`BIcon${icon}`"
      :class="cssClasses()"
      :height="`${scale}em`"
      :width="`${scale}em`"
    />
</template>

<script>
export default {
    props: {
        icon: {
            type: String
        },
        scale: {
            type: String,
            default: '1'
        },
        variant: {
            type: String,
            default: 'regular'
        }
    },

    computed: {
    },

    methods: {
        cssClasses() {
            return `bi bi-icon text-${this.variant}`
        },

        getVariant(){
            let valid = ['solid', 'regular']

            if(valid.includes(this.variant)){
                return this.variant
            }

            return 'regular'
        }
    }
}
</script>