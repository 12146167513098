<template>
    <template v-if="formOptions.length > 0">
    <template v-for="(opt, idx) in formOptions" :key="`radio-${idx+1}`">
        <input class="form-check-input" type="radio" :name="`radio-${idx+1}`" :value="`${opt.value}`">
        <label class="form-check-label" :for="`radio-${idx+1}`">
            {{opt.text}}
        </label>
    </template>
    </template>
    <template v-else>
        <slot/>
    </template>
</template>

<script>
export default {
    props: {
        options: {
            type: [Array, Object],
            default: () => []
        },
        id: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        labelFor: {
            type: String,
            default: ''
        },
    },

    computed: {
        formOptions() {
            return this.normalizeOptions(this.options)
        }
    },    

    methods: {
        get(obj, propName){
            var val = obj[propName];
            if(this.isUndefined(val) || val === null){
                return '';
            }
            return val;
        },
        isObject(x){
            return x != null && (typeof x === 'object' || typeof x === 'function');
        },
        isUndefined(x){
            return x === undefined
        },
        stripTags(text){
            var div = document.createElement("div");
            div.innerHTML = text;
            return div.textContent || div.innerText || "";
        },        
        normalizeOption(option, key = null) {
            // When the option is an object, normalize it
            if (this.isObject(option)) {
                const value = this.get(option, this.valueField)
                const text = this.get(option, this.textField)
                return {
                    value: this.isUndefined(value) ? key || text : value,
                    text: this.stripTags(String(this.isUndefined(text) ? key : text)),
                    html: this.get(option, this.htmlField),
                    disabled: Boolean(this.get(option, this.disabledField)),
                    optgroup: false
                }
            }
            return {
                value: key || option,
                text: this.stripTags(String(option)),
                disabled: false,
                optgroup: false
            }
        },
        normalizeOptions(options) {
            var items = []

            if(!this.isObject(options) && !Array.isArray(options)){ return [] }

            if (this.isObject(options)) {
                var fixed_options = []
                Object.keys(options).forEach((key) => {
                    fixed_options = [...fixed_options, options[key] || {}]
                })
                options = fixed_options
            }

            options.forEach((option) => {
                if(option['label'] && option['options']){
                    items = [...items, {optgroup: true, close: false, label: option['label'], options: this.normalizeOptions(option['options']) }]
                } else {
                    items = [...items, this.normalizeOption(option)]
                }
            })

            return items
        }
    }
}
</script>