export default {
  /**
   * The Form represented as a JSON object.
   * 
   * @param {Object}
   */
  form: {},

  /**
   * The current Form Variant represented as a JSON object.
   * 
   * @param {Object}
   */
   variant: {},

  /**
   * The list of Form Variants.
   * 
   * @param {Object}
   */
   variants: [],

  /**
   * The default list of Form Variants.
   * 
   * @param {Object}
   */
   defaultVariants: [
    {
      form_id: null,
      variant_type: 'thedefault',
      type: 'domestic',
      heading: '',
      intro: '',
      redirect: 'thank-you',
      steps: [
        []
      ],
      controls: {
        previous: { text: 'Previous' },
        next: { text: 'Next' },
        submit: { text: 'Request Info' }
      },
      privacy: {
        label: '[PARTNER_NAME] has engaged Wiley University Services to help support your education journey. Wiley will contact you shortly in response to your request for information. [LINK_1]. [LINK_2].',
        links: [
          {
            tag: 'LINK_1',
            text: 'Learn more about Wiley',
            url: 'https://universityservices.wiley.com/student-journey-support-team/'
          },
          {
            tag: 'LINK_2',
            text: 'Privacy Policy',
            url: 'https://policies.edusites.net/privacyus'
          }
        ],
      },
      program_load: []
    },
   ],


  /**
   * The original Form represented as a JSON object.
   * 
   * This is used to store the unmodified version of a Form while editing.
   * 
   * @param {Object}
   */
  originalForm: {},

  /**
   * The default representation of a form object.
   * 
   * @param {Object}
   */
  defaultForm: {
    account_id: null,
    name: '',
    variants: [],
  },

  /**
   * The conditional logic triggers represented as an array (in the order of variants).
   * 
   * 
   * @param {Object}
   */
  conditionalTriggers: [{'visibility': [], 'display': []}],  

  /**
   * The current variant index.
   * 
   * 
   * @param {number}
   */
  currentVariantIndex: 0

}