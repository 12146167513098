<template>
    <div class="form-check" :class="{'form-check-inline': inline}">
        <input class="form-check-input" type="checkbox" :name="name" :value="fieldValue" :checked=checked @click="handleClick(localChecked)">
        <label class="form-check-label" :for="name">
            <slot/>
        </label>
    </div>

</template>

<script>
import { nextTick } from "vue";

export default {
    props: {
        name: {
            type: String
        },
        id: {
            type: String
        },
        type: {
            type: String
        },
        inline: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: [String, Boolean],
        },
        checked: {
            type: Boolean
        },
        value: {
            type: String,
        },
        uncheckedValue: {
            type: String,
        },
        disabled: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],

    data() {
        return {
            localChecked: false
        }
    },

    computed: {

        /**
        * The value that is passed from the parent component through `v-model`.
        * @param {String} val
        * 
        * @returns {String}
        */
        fieldValue: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        },

    },

    methods: {
        async handleClick(checked) {
            await nextTick() // otherwise this.modelValue will lag behind
            this.localChecked = !checked //update checked state to opposite
            if(!!this.value && !!this.uncheckedValue) { //if custom checked and unchecked values are passed in
                if(this.localChecked) {
                    this.fieldValue = this.value
                } else {
                    this.fieldValue = this.uncheckedValue
                }
            }
            else {
               this.fieldValue = this.localChecked 
            }
            
        }
    },

    created() {
        this.localChecked = this.checked //if the checked prop is passed in, the local checked should be true
        if(!!this.value && !!this.uncheckedValue) { //if custom checked and unchecked values are passed in
            if(this.localChecked) {
                this.fieldValue = this.value
            } else {
                this.fieldValue = this.uncheckedValue
            }
        } 
    }
}
</script>
<style lang="scss" scoped>

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0;
}

.form-check-input {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
}

.form-check-label {
    display: inline-flex;
    align-items: center;
    margin-right: 0.25rem;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 0.25rem;
}
</style>
