<template v-if="formOptions.length > 0">
    <select class="form-select" v-model="selectedValue" @change="handleChange">
        <template v-for="(opt, idx) in formOptions">
            <template v-if="opt.options">
                <optgroup :label="opt.label" :key="`optgroup-${idx}`">
                    <option v-for="(subopt, idxx) in opt.options" :value="subopt.value" :key="`subopt-${idxx}`"
                        :disabled="opt.disabled">{{ subopt.text }}</option>
                </optgroup>
            </template>
            <template v-else>
                <option :value="opt.value" :key="`option-${idx}`" :disabled="opt.disabled">{{ opt.text }}</option>
            </template>
        </template>
    </select>
</template>

<script>
export default {
    props: {
        options: {
            type: [Array, Object],
            default: () => []
        },
        modelValue: {
            type: String,
            default: null
        },
        valueField: {
            type: String,
            default: 'value'
        },
        textField: {
            type: String,
            default: 'text'
        },
        htmlField: {
            type: String,
            default: 'html'
        }   
    },
    
    emits: ['update:modelValue'],

    data(){
        return {
            selectedValue: this.modelValue || (this.options.length > 0 ? this.options[0][this.valueField] : null)// Initialize selectedValue with the v-model value
        }
    },

    computed: {
        formOptions() {
            return this.normalizeOptions(this.options)
        }
    },

    watch: {
        modelValue(newValue) {
            this.selectedValue = newValue; // Update selectedValue when v-model value changes
        }
    },
  
    methods: {
        handleChange(event) {
          this.selectedValue = event.target.value; // Update selectedValue when select value changes
          this.$emit('update:modelValue', this.selectedValue); // Emit selectedValue to parent component
        },   

        get(obj, propName){
            var val = obj[propName];
            if(this.isUndefined(val) || val === null){
                return '';
            }
            return val;
        },
        isObject(x){
            return x != null && (typeof x === 'object' || typeof x === 'function');
        },
        isUndefined(x){
            return x === undefined
        },
        stripTags(text){
            var div = document.createElement("div");
            div.innerHTML = text;
            return div.textContent || div.innerText || "";
        },
        normalizeOptions(options) {
            const normalizedOptions = [];
            options.forEach(option => {
                if (option.options) {
                    normalizedOptions.push({
                        optgroup: true,
                        label: option.label,
                        options: option.options.map(suboption => ({
                        value: suboption[this.valueField],
                        text: suboption[this.textField],
                        disabled: option.disabled
                        }))
                    });
                } else {
                    normalizedOptions.push({
                        value: option[this.valueField],
                        text: option[this.textField],
                        disabled: option.disabled
                    });
                }
            });
            return normalizedOptions;
        }
    }
}
</script>