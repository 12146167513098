<template>
    <table class="table">
        <template v-if="fields.length > 0">
            <thead>
                <tr>
                    <th v-for="(field, idx) in fields" scope="col" :key="`th-${idx}`">
                        <template v-if="field.sortable">
                            <span style="float: left; margin-right: 3px;">
                                <div style="display: flex; flex-direction: column;">
                                    <b-icon
                                    :icon="isSortedBy(getSortKey(field)) ? 'CaretUpFill' : 'CaretUp'"
                                    variant="primary"
                                    @click="setCurrentSort(getSortKey(field))"
                                    style="margin-bottom: -3px;"
                                    />
                                    <b-icon
                                    :icon="isSortedBy(`${getSortKey(field)}-`) ? 'CaretDownFill' : 'CaretDown'"
                                    variant="primary"
                                    @click="setCurrentSort(`${getSortKey(field)}-`)"
                                    style="margin-top: -3px;"
                                    />
                                </div>
                            </span>
                        </template>
                        {{ getTableHeader(field) }}
                    </th>
                </tr>
            </thead>
        </template>
        <template v-if="items.length > 0">
            <tbody>
                <tr v-for="(item, tridx) in items" :key="`tr-${tridx}`">
                    <td v-for="(field, tdidx) in fields" scope="col" :key="`td-${tdidx}`">
                        <template v-if="!$slots[`cell(${field['key']})`]">
                            {{ displayValue(item, field) }}
                        </template>
                        <template v-else>
                            <slot name="cell(actions)" v-bind:item="item" />
                        </template>
                    </td>
                </tr>
            </tbody>
        </template>
        <template v-else-if="busy && $slots['table-busy']">
            <tr>
                <td :colspan="fields.length">
                    <div class="justify-content-center">
                        <slot name="table-busy" />
                    </div>
                </td>
            </tr>
        </template>
    </table>
</template>

<script>
import { stringToTableHeader } from '../../core/Functions' 
import _ from 'lodash'

export default {
    props: {
        busy: {
            type: Boolean,
            default: true
        },
        currentPage: {
            type: Number,
            default: 1
        },
        currentSort: {
            type: String,
            default: null
        },
        fields: {
            type: Array,
            default: () => []
        },
        filter: {
            type: String
        },
        items: {
            type: Array,
            default: () => []
        },
        perPage: {
            type: Number,
            default: 1
        },
        primaryKey: {
            type: String
        },
        type: {
            type: String,
            default: 'button'
        },
    },

    emits: ['update:currentSort'],

    methods: {
        displayValue(item, field){
            if(!field['formatter'] || typeof field['formatter'] !== 'function'){
                return _.get(item, field['key'])
            }

            return field['formatter'](_.get(item, field['key']))
        },

        isSortedBy(param){
            return this.currentSort == param
        },

        setCurrentSort(param) {
            this.$emit('update:currentSort', param);
        },

        getSortKey(field){
            if(!!field.sort_key && field.sort_key.length > 0){
                return field.sort_key
            }

            return field.key
        },

        getTableHeader(field){
            if(!!field.label && field.label.length > 0) {
                return field.label
            } else if(!!field.key && field.key.length > 0) {
                return stringToTableHeader(field.key)
            } 

            return ''
        }
    },
}
</script>