<template>
    <div class="form-check" :class="{'form-check-inline': inline}">
        <input class="form-check-input" type="radio" :name="name" :value="value" @change="$emit('update:modelValue', $event.target.value)">
        <label class="form-check-label" :for="name">
            <slot/>
        </label>
    </div>

</template>

<script>
export default {
    props: {
        name: {
            type: String
        },
        id: {
            type: String
        },
        type: {
            type: String
        },
        inline: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue']
}
</script>
<style lang="scss" scoped>
.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-input {
    display: inline-flex;
    align-items: center;
    margin-right: 0.25rem;
}

.form-check-label {
    display: inline-flex;
    align-items: center;
    margin-right: 0.25rem;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}
</style>
